#open-kuaikan {
  position: fixed;
  bottom: 493px;
  left: 50%;
  transform: translateX(-50%);
  height: 72px;
  background-color: #ffe120;
  border-radius: 100px;
  text-align: center;
  padding: 18px 48px;
  z-index: 999;

  .text {
    color: #222;
    font-size: 30px;
    line-height: 36px;
  }

  .close-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -15px;
    right: -15px;
  }
}