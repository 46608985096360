
.kk-adv-modal__wrap {
  .kk-modal__container {
    padding: 0;
    width: 750px !important;
    max-width: 750px !important;
    .kk-modal__container__body {
      padding: 0;
    }
  }
}