// 常用颜色
.color-222 {
  color: #222;
}
.color-333 {
  color: #333;
}
.color-666 {
  color: #666;
}
.color-999 {
  color: #999;
}
.color-fff {
  color: #fff;
}

.bg-ff {
  background-color: #fff;
}
.bg-f5 {
  background-color: #f5f5f5;
}

// 常用字体大小（和行高绑定）
.fs-20 {
  font-size: 20px;
  line-height: 24px;
}
.fs-24 {
  font-size: 24px;
  line-height: 30px;
}
.fs-26 {
  font-size: 26px;
  line-height: 32px;
}
.fs-28 {
  font-size: 28px;
  line-height: 36px;
}
.fs-32 {
  font-size: 32px;
  line-height: 40px;
}

// flex相关
.flex-row {
  display: flex;
  flex-direction: row;
}
.items-center {
  align-items: center;
}
.content-center {
  justify-content: center;
}
.flex-center {
  align-items: center;
  justify-content: center;
}
.between-center {
  align-items: center;
  justify-content: space-between;
}

// 绝对定位居中（父元素须 position: relative）
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fw-bold {
  font-weight: 500;
}
.android,
.is-android {
  .fw-bold {
    font-weight: 700;
  }
}

.radius-50 {
  border-radius: 50%;
}

.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}