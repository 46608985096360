@import url('assets/style/mixin.less');
@import url('./common.less');
@import url('assets/style/appTabBar.less');

/* 迁移自taro-components.css */
.taro_router {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden
}

.taro_page {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 88px /*--header-height*/;
  padding-top: var(--header-height, 0);
}

.taro-tabbar__container .taro-tabbar__panel {
  overflow: hidden;
}
/* 迁移自taro-components.css */

.wk-webview-bang {
  --safe-top: 88px;
}

.wk-webview {
  --safe-top: 56px;
}

:root {
  --vh: 1vh;
}

// ！！！！！！！！修改此处的高度，所有使用到--header-height的css的地方的兼容代码都需要改动
body {
  --header-height: calc(var(--safe-top, 0px) + 88px);
  --tabbar-height: 115px;
  // 在低端机型上没法用calc计算constant/env和数字相加来赋值var变量，通过这种方式低端机型浏览器无法识别constant/env
  // css最后取的是 --tabbar-height: calc(115px + constant/env(safe-area-inset-bottom))
  // 但由于constant/env无法识别，整个表达式错误了，所以最后--tabbar-height相当于0，因此得用support的方式来处理兼容
  // 如果直接赋值css属性则是没问题，例如 height: calc(115px + constant/env(safe-area-inset-bottom))
  @supports (height: constant(safe-area-inset-bottom)) {
    --tabbar-height: calc(115px + constant(safe-area-inset-bottom));
  }
  @supports (height: env(safe-area-inset-bottom)) {
    --tabbar-height: calc(115px + env(safe-area-inset-bottom));
  }
  font-family: "PingFang SC", "Microsoft YaHei", "Helvetica Neue", sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

// 由于使用了react的fast refresh，错误提示会有个iframe框出来，进行屏蔽
body > iframe#react-refresh-overlay {
  display: none;
}

img[src=''],
img:not([src]) {
  opacity: 0;
}

.preview-image .swiper-wrapper {
  flex-direction: row;
}

.taro__toast {
  position: relative;
  z-index: 9999;

  & > div:nth-child(2) {
    line-height: 1.2 !important;
    padding: 20px 24px !important;
    border-radius: 16px !important;
    background: rgb(0 0 0 / 70%) !important;
    max-width: 80vw !important;
    width: fit-content;

    > p:nth-child(2) {
      font-size: 28px !important;
    }
  }
}

.weui-tabbar__icon {
  width: 56px !important;
  height: 56px !important;
}

/* 小程序全局设置border-box */
view,
scroll-view,
swiper,
icon,
text,
progress,
button,
checkbox,
form,
input,
label,
picker,
slider,
switch,
textarea,
navigator,
image,
video,
camera,
map,
canvas,
open-data,
web-view,
div,
span,
taro-text-core,
taro-view-core,
taro-scroll-view-core,
.taro-scroll {
  box-sizing: border-box;
}

view,
text,
span,
taro-text-core {
  font-size: 32px;
  color: #333333;
}

text,
textarea,
span,
taro-text-core {
  line-height: 1;
}

view,
taro-view-core {
  display: flex;
  flex-direction: column;
}

.taro-tabbar__container .taro_router {
  min-height: calc(100vh - 115px);
  min-height: calc(100vh - var(--tabbar-height, 115px));
}

.taro-tabbar__container .taro_page.taro_tabbar_page {
  max-height: calc(100vh - 115px);
  max-height: calc(100vh - var(--tabbar-height, 115px));
}

.taro-tabbar__tabbar {
  z-index: 999;
  margin: 0 !important;
  height: 115px;
  height: var(--tabbar-height, 115px);
  flex-shrink: 0;
}

.weui-tabbar {
  flex-direction: row;
  background-color: #fff !important;
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  
  .weui-tabbar__item {
    .weui-badge_dot {
      transform: translate(50%, -50%);
      right: 0 !important;
      border: 2px solid #fff;
    }
  }
}

.weui-tabbar:before {
  border-top: 1px solid #ebebeb !important;
}

.taro-modal__foot {
  flex-direction: initial;

  .taro-model__btn:nth-child(2) {
    color: #ff6600 !important;
  }
}

.page {
  background-color: #f5f5f5;
  height: 100%;
}

.white-back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.yellow {
  color: #ff6600;
}

.grey {
  color: #b3b3b3;
}

.fs20 {
  font-size: 20px;
}

.fs24 {
  font-size: 24px;
}

.fs26 {
  font-size: 26px;
}

.fs28 {
  font-size: 28px;
}

.fs36 {
  font-size: 36px;
}

.c333 {
  color: #333;
}

.red {
  color: #ff1f1f;
}

.c999 {
  color: #999;
}

.c666 {
  color: #666;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.more-icon {
  width: 40px;
  height: 40px;
}

.article-wrap {
  background-color: #ffffff;
  padding: 32px 24px;
  width: 100%;
  margin: 24px 0;
}

.popup {
  background-color: #333;
}

.star {
  color: red;
  padding-right: 10px;
}

.loading-end {
  text-align: center;
  padding: 24px 0;
  color: #b3b3b3;
  font-size: 26px;
}

.btn::after {
  border: none;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mt8 {
  margin-top: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.ml8 {
  margin-left: 8px;
}

.ml16 {
  margin-left: 16px;
}

.mr8 {
  margin-right: 8px;
}

.mr16 {
  margin-right: 16px;
}

.mr22 {
  margin-right: 22px;
}

.overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.color-theme {
  color: #ff6600;
}

.status {
  font-size: 24px;
  color: #a3a3a3;
  text-align: center;
}

.wm-tabs {
  height: 88px;
  padding: 0 46px;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;

  .item {
    align-items: center;
    padding: 30px 0 8px;

    .text {
      color: #333;
      font-size: 24px;
    }

    .line {
      width: 48px;
      height: 4px;
      margin-top: 20px;
      border-radius: 2px;
    }

    .active {
      background-color: #ff6600;
    }
  }
}

.wm-title {
  padding: 32px 24px;

  .text {
    font-size: 28px;
    color: #333;
  }
}

.wm-button-1 {
  height: 72px;
  border-radius: 36px;
  background-image: linear-gradient(90deg, #ff8400, #ff4d00);
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 28px;
    color: #fff;
  }
}

.wm-button-2 {
  padding: 14px 24px;
  border-radius: 24px;
  background-image: linear-gradient(90deg, #ff8400, #ff4d00);

  .text {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
  }
}

//input光标颜色
input,
textarea {
  caret-color: #ffe120 !important;
}

.taro-scroll-view__scroll-y {
  display: block;
}

.weui-picker__hd {
  display: flex;
  flex-direction: row;
}

.opacity-four {
  color: rgb(0 0 0 / 40%);
}

.opacity-eight {
  color: rgb(0 0 0 / 80%);
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
  display: none;
}

.bold {
  font-weight: 500 !important;
}

.bolder {
  font-weight: 700 !important;
}

input::placeholder {
  color: #999;
}

.weui-picker__action {
  color: #ff6600 !important;

  &:first-child {
    color: #999 !important;
  }
}

// 商品详情底部操作栏
.goods-detail-bottom {
  height: 112px;
  width: calc(100vw - 59px);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 0 24px 0 36px;
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background: #fff;
  box-sizing: content-box;

  .l-content {
    flex-direction: row;
    align-items: center;
    flex: 1;

    .down-text {
      align-items: center;
      position: relative;
      margin-right: 35px;

      .box {
        min-width: 32px;
        height: 32px;
        background-color: #ff4d4d;
        border-radius: 30px 30px 30px 12px;
        border: 4px solid #fff;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 30px;
        top: -15px;
        padding: 0 8px;
        z-index: 8;

        .num {
          font-size: 18px;
          color: #fff;
          font-weight: 500;
        }
      }

      .dh {
        width: 44px;
        height: 44px;
        margin-bottom: 8px;
      }

      .text {
        font-size: 18px;
        color: #333333;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .btn-box {
    flex-direction: row;
    justify-content: space-between;
    width: 476px;
    margin-left: 16px;

    .btn-item {
      display: flex;
      flex-direction: row;
      height: 72px;
      background: linear-gradient(90deg, rgb(255 132 0 / 100%) 0%, rgb(255 77 0 / 100%) 100%);
      border-radius: 36px;
      flex: 1;
      font-size: 28px;
      color: #fff;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      margin-left: 16px;

      .price {
        font-size: 28px;
        color: #fff;
        line-height: 28px;
        margin-bottom: 4px;
        font-weight: 500;
      }

      .name {
        font-size: 16px;
        color: #fff;
        line-height: 16px;
        font-weight: 500;
      }

      &.btn-item-light {
        background: linear-gradient(90deg, rgb(255 209 25 / 100%) 0%, rgb(255 162 0 / 100%) 100%);
      }

      &.btn-item-disable {
        background: linear-gradient(90deg, rgb(230 230 230 / 100%) 0%, rgb(204 204 204 / 100%) 100%);

        .btn-item-text {
          font-size: 28px;
          position: relative;
          top: -3px;
        }
      }

      &.btn-item-o {
        background: #fff3eb;

        .price {
          color: #ff6600;
        }

        .name {
          color: #ff6600;
        }
      }

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.select-right-weui-input-color-black {
  .wm-form-item {
    .select-right {
      .weui-input {
        color: #000 !important;
        opacity: 1 !important;
      }
    }
  }
}

#app {
  .marketing {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  // h5下拉
  .rmc-pull-to-refresh-content {
    &.rmc-pull-to-refresh-transition {
      // transform: translate3d(0px, 0px, 0px) !important;
      transform: none !important;
    }

    .rmc-pull-to-refresh-indicator {
      margin-top: -60px;

      div {
        width: 0 !important;
        height: 0 !important;
        background: transparent !important;
      }
    }
  }

  .scroll-view {
    -webkit-overflow-scrolling: touch;
  }

  .repay-form .taro-textarea {
    font-size: 28px !important;
  }
}

.price-goods > .wm-price-default {
  max-width: 4.71733rem !important;
}

// 快看按钮UI样式start
.kk-btn {
  min-width: 216px;
  width: 100%;
  height: 72px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 30px;
  font-weight: 500;
  color: #222222;
  box-sizing: border-box;
  border: none;
  outline: none;

  &.small {
    font-weight: 400 !important;
    min-width: 92px;
    height: 40px;
    font-size: 22px;
  }

  &.medium {
    min-width: 168px;
    height: 56px;
    font-size: 26px;
  }

  &.big {
    width: auto;
    height: 88px;
    line-height: 88px;
    font-size: 32px;
  }

  &.btn-primary {
    color: #222;
    background-color: #ffe120;

    &.is-disabled {
      color: #999;
      background-color: rgb(255 225 32 / 30%);
    }

    &.is-disabled-but-can-click {
      color: #666666;
      background: #f5f5f5;
    }
  }

  &.btn-default {
    // font-weight: normal;
    background-color: #f5f5f5;

    &.is-disabled {
      color: #cccccc;
      background: #f7f8fa;
    }
  }

  &.btn-outline {
    background-color: #fff;
    .border(2px, solid, #ccc, 100px);

    &.is-disabled {
      border: none;

      &::after {
        border: none;
      }
      background: #f7f8fa;
      color: #cccccc;
    }

    &.bg-grey {
      background-color: #f5f5f5;
    }
  }
}

.is-android {
  .kk-btn {
    font-weight: 700;
  }

  .android-hack-4px {
    padding-top: 4px;
  }

  .android-hack-2px {
    padding-top: 2px;
  }
}

// 覆盖taro自带Button样式
taro-button-core.kk-btn::after {
  border: none;
}

// 白底圆角内容块
.content-block {
  background-color: #fff;
  border-radius: 24px;
  margin: 0 16px 20px;
  padding: 32px 20px;
}

// fix:iOS disabled input text
.wm-stepper {
  input:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

// aspectFit模式的图片居中展示
taro-image-core[mode='aspectFit'] {
  position: relative;

  > img.taro-img__mode-aspectfit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.kk-tab-bar-new {
  box-sizing: content-box;
  display: block;
  position: absolute;
  top: -10px;
  right: -34px;
  width: 54px;
  height: 24px;
  background: #ff4d4d;
  border: 4px solid #ffffff;
  border-radius: 24px 24px 24px 6px;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.is-android.kk-tab-bar-new {
  line-height: 30px;
}

.is-huawei.kk-tab-bar-new {
  line-height: 26px;
}

// 函数调用式的loading
.fn-loading {
  .fn-loading-box {
    min-width: 214px;
    height: 214px;
    background: rgb(0 0 0 / 70%);
    border-radius: 24px;
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    &.simple {
      background: rgb(0 0 0 / 0%);
    }

    .fn-loading-icon {
      width: 60px;
      height: 60px;
      margin: 0;
      background-image: url("https://f12stag.kkmh.com/game/200511/Cr6xuuOTl.png");
      display: inline-block;
      vertical-align: middle;
      background-size: contain;
      background-repeat: no-repeat;

      &.simple {
        width: 40px;
        height: 40px;
        background-image: url("https://f12stag.kkmh.com/game/200511/6ZTLFjzip.png");
      }
    }

    .fn-loading-title {
      color: #fff;
      font-size: 28px;
      margin-top: 8px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .fn-loading-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    top: 0;
    left: 0;
    z-index: 9998;
  }
}

// taro Image 组件的mode属性，部分适配方式需要hack
.taro-img__mode-heightfix {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
}

.taro-img__mode-aspectfill {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.not-kuaikan .app_content,
.not-kuaikan .app_content > div {
    height: 100%;
}

// 跳转企点客服前的确认弹窗
.qidian-modal {
  .kk-modal__container__body {
    white-space: pre-wrap;
    text-align: center;
  }
}