.kk-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.kk-modal__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 80%);
}

.kk-modal--center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .kk-modal__container {
    width: 576px;
    max-width: 80vw;
    border-radius: 24px;
  }

  .kk-modal__container__body {
    padding: 0 48px;
  }
}

.kk-modal__container {
  padding: 20px 0 48px;
  position: relative;
}

.kk-modal__container__header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  line-height: 44px;
  padding: 24px 40px 16px;
  text-align: center;
  color: #333;
}

.kk-modal__container__header__close {
  position: absolute;
  top: 50%;
  right: 28px;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
}

.kk-modal--bottom {
  .kk-modal__container {
    position: absolute;
    width: 100%;
    z-index: 1;
    border-radius: 24px 24px 0 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.kk-modal__container__body {
  font-size: 28px;
  line-height: 1.6;
  color: #666;
}

.kk-modal__container__footer__btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 48px 0;
}

.kk-modal__container__footer__btn {
  flex: 1 1 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 88px;

  &:nth-child(2) {
    margin-left: 24px;
  }
}

.kk-modal__bottom__close {
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -116px;
}

.kk-modal--interaction {
  .kk-modal__container__header {
    padding: 24px 40px;
    font-size: 32px;
    line-height: 40px;
  }
  
  .kk-modal__container__header__close {
    right: 16px;
  }

  .kk-modal__container {
    padding: 0;
  }

  .kk-modal__container__body {
    padding: 0;
  }
}
